import {usePostStore} from "@/stores/post";
import {ref} from "vue";

export function useViewCounter({
    postId,
    isUser = false,
    viewTimeout = 3000
}) {

    const postStore = usePostStore();

    const hasViewed = ref(false)
    const isViewing = ref(false)
    const viewingTimeout = ref(false)


    // TODO DEBOUNCE ?
    // const lastViewed = ref(null)

    const addView = (isIntersecting) => {

        if (!isIntersecting) {
            if (viewingTimeout.value) {
                clearTimeout(viewingTimeout.value)
            }
            isViewing.value = false
            return
        }

        if (isUser && !hasViewed.value && isIntersecting) {

            if (viewingTimeout.value) {
                clearTimeout(viewingTimeout.value)
            }

            isViewing.value = true

            viewingTimeout.value = setTimeout(() => {

                // console.log(postId)
                postStore.addView(postId)
                .catch(err => {
                    //     TODO not counted
                });
                isViewing.value = false
                hasViewed.value = true
            }, viewTimeout)
        }
    }


    return {
        addView
    }

}
