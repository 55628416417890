<template>
    <div>
        <div
            v-if="!loading"
            :id="`directive-fullscreen-wrapper-${id}`"
            class="post-image-wrapper cursor-pointer"
            :class="[imageOrientation, (postIsLocked && checkedVisibility === 'blur' ? 'position-relative' : '')]"
            @click="toggleFullscreen"
        >
            <span v-if="fullScreen" class="text-white position-absolute top-0 start-50 translate-middle pt-5" aria-label="Close">
                {{$t('globals.clickToClose')}}
            </span>
            <img
                v-if="media.original === media.thumb"
                :src="`${media.original}`"
                class="img-fluid"
                :class="checkedVisibility === 'blur' ? 'blur-img' : ''"
            />
            <v-lazy-image
                v-else
                :src="`${media.original}`"
                :src-placeholder="`${media.thumb}`"
                class="img-fluid"
                :class="checkedVisibility === 'blur' ? 'blur-img' : ''"
            ></v-lazy-image>
            <span v-if="postIsLocked && checkedVisibility === 'blur'" class="position-absolute start-50 top-50 translate-middle">
                <ContentUnlockerButton></ContentUnlockerButton>
            </span>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import VLazyImage from "v-lazy-image";
import { api as fullscreen } from 'vue-fullscreen'
import {useUserStore} from "@/stores/user";
import {useRegistrationCtaModalStore} from "@/stores/registrationCtaModal";
import ContentUnlockerButton from "@/components/Buttons/ContentUnlockerButton.vue";

const props = defineProps({
    id: {
        type: String,
        default: '0'
    },
    media: {
        type: Object,
        default: () => ({
            original: false,
            thumb: false
        })
    },
    checkedVisibility: {
        type: String,
        default: 'blur'
    },
    postIsLocked: {
        type: Boolean,
        default: false,
    },
})

const fullScreen = ref(false)
const loading = ref(true)
const imageOrientation = ref("landscape")

const isLocked = computed(() => {
    return props.media.media_type.startsWith('locked')
})

function fullscreenCallback(isFullscreen) {
    fullScreen.value = isFullscreen
    if (isFullscreen) {
        document.body.classList.add('overflow-hidden')
    }
    else {
        document.body.classList.remove('overflow-hidden')
    }
}

async function toggleFullscreen(isFullscreen) {
    if (!isLocked.value){
        await fullscreen.toggle(
            document.querySelector(`#directive-fullscreen-wrapper-${props.id}`), {
                teleport: false,
                pageOnly: true,
                callback: fullscreenCallback
            }
        )
    }
    else if (
        useUserStore().cannot('zoom-post-image')
        && (
            props.checkedVisibility === 'blur'
            || (
                props.checkedVisibility === 'teaser'
                && props.media.media_type === 'locked_video/ikonstar'
            )
        )) {
        useRegistrationCtaModalStore().showModal()
        return false
    }
}

onMounted(() => {
    let img = new Image();
    img.addEventListener('load', function() {
        const {
            naturalHeight,
            naturalWidth
        } = img;
        if (naturalHeight >= naturalWidth) {
            imageOrientation.value = "portrait";
        }
        else {
            imageOrientation.value = "landscape";
        }
        loading.value = false
    });
    img.src = (props.media.thumb !== '' ? props.media.thumb : props.media.original)
    // img.src = (props.postIsLocked && props.checkedVisibility === 'blur')
    //     ? (props.media.thumb !== '' ? props.media.thumb : props.media.original)
    //     : props.media.original
})
</script>


<style scoped lang="scss">

.post-image-wrapper {
    background-size:cover;
    background-position: center center;
    transition: all .1s ease-in;

    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: hidden;
    //border: 1px solid rgb(47, 51, 54);
    max-height: 510px;

    &.portrait:not(.fullscreen) {
        max-width: 402px;
    }

    &.fullscreen {
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 1040;
        background-color: rgba(0, 0, 0, 0.99);
        max-height: 100%;

        height: 100vh !important;
        width: 100vw !important;

        img {
            max-height: 100%;
        }
    }
}

.blur-img {
    filter: blur(14px) !important;
}

.v-lazy-image {
    filter: blur(5px);
    transition: filter 1.6s;
    will-change: filter;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

</style>
