import {defineStore} from 'pinia'
import {computed, ref, watch} from 'vue'
import ApiService from '@/services/ApiService'
import {QueryParamBuilder} from '@/helpers/QueryParamBuilder'
import {useUserStore} from "@/stores/user";

export const useCreatorStore = defineStore('creator', () => {
    const creator = ref({
        id: null,
        ulid: null,
        username: '',
        slug: '',
        bio: '',
        about: '',
        gender: '',
        avatar: '',
        headerImg: {
            original: '',
            thumb: '',
        },
        modules: null,
        created_at: null,
        status: '',
        is_ikonstar: false,
        is_verified: false,
        identity_verification: {},
        can_accept_payment: false,
        stripe_account_id: '',
        need_tutorial: false,
        tutorial: {
            mandatory: [
                {
                    'billing_verified': false,
                    'id_verified': false,
                },
                {
                    'avatar': false,
                    'header': false,
                    'about': false,
                    'bio': false,
                    'socials': false,
                },
                {
                    'sub_perks': false,
                    'five_posts': false,
                    'stripe_connect': false,
                },
            ],
            optional: {
                'modules': false,
                'private_video_calls': false,
                'messenger_settings': false,
            },
        }
    })

    const requestedSettingsMenu = ref("")

    const modules = computed(() => creator.value.modules?.modules)

    const hasCreatorSelected = computed(() => creator.value.id !== null)

    const creatorInStorage = localStorage.getItem('creator-store')
    if (creatorInStorage) {
        const storageCreator = JSON.parse(creatorInStorage)
        creator.value = {
            ...creator.value,
            ...storageCreator,
        }
    }
    watch(
        () => creator,
        (state) => {
            localStorage.setItem('creator-store', JSON.stringify(state.value))
        },
        {deep: true}
    )

    function hasModule(_module) {
        return modules.value
            ? modules.value[_module]
            : true
    }

    function setCreator(_creator = {}) {
        creator.value = {..._creator}
    }

    function setModules(_modules) {
        creator.value.modules = _modules
    }

    function setRequestedSettingsMenu(_menu = "") {
        requestedSettingsMenu.value = _menu
    }


    /**
     * Find Creator By Slug
     * @param _slug
     * @returns {Promise<axios.AxiosResponse>|Promise<unknown>}
     */
    function findCreatorBySlug(_slug) {
        const maxLife = 1000 * 60 // 1 minute
        const creatorInStorage = localStorage.getItem(`creator_${_slug}`)
        const storageCreator = creatorInStorage ? JSON.parse(creatorInStorage) : {time_fetched: new Date().getTime()}

        if (creatorInStorage && ((new Date().getTime()) - storageCreator.time_fetched < maxLife)) {
            const storageCreator = JSON.parse(creatorInStorage)
            creator.value = {
                ...creator.value,
                ...storageCreator,
            }
            return new Promise((resolve) => {
                resolve(storageCreator)
            })
        } else {
            let preview = useUserStore().user.preview ? '/preview' : ''
            ApiService.init()
            return ApiService.get(`creator${preview}/${_slug}`)
            .then((result) => {
                creator.value = {...result.data}

                localStorage.setItem(
                    `creator_${_slug}`,
                    JSON.stringify({
                        ...creator.value,
                        time_fetched: new Date().getTime()
                    })
                )
                return result.data
            })
        }
    }

    function checkRegistrationPasswordSignature(_data) {
        const url =
            '/creator/registration-password' + QueryParamBuilder.encode(_data)
        return ApiService.get(url)
    }

    function createFirstPassword(_data, _query) {
        const url =
            `/creator/${creator.value.slug}/registration-password` +
            QueryParamBuilder.encode(_query)
        return ApiService.post(url, _data)
    }

    /**
     * Get all plans of a creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function plans() {
        return ApiService.get(`creator/${creator.value.id}/plans`)
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    /**
     * Get all plans of a creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function faqs() {
        return ApiService.get(`creator/${creator.value.id}/faqs`)
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    /**
     * Get a specific plan of a creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function planById(creatorId, planId) {
        return ApiService.get('creator/' + creatorId + '/plans', planId)
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    async function getCreatorPosts(creatorId) {
        return ApiService.get('creator/' + creatorId + '/posts')
        .then((data) => {
            return data.data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    async function getSocialLinks(creatorId) {
        return ApiService.get('creator/' + creatorId + '/socials')
        .then((data) => {
            return data.data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    async function getLiked(creatorId, type, cursor = '', filter = {}) {
        return ApiService.getAll('creator/' + creatorId + '/liked/' + type, {
            params: {
                cursor,
                filter,
            },
        })
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    async function getSubscribers(creatorId) {
        return ApiService.get(`creator/${creatorId}/subscribers`)
        .then(({data}) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    async function getAllSubscribers(creatorId, params = {}) {
        return ApiService.getAll('creator/' + creatorId + '/subscribers', {
            params,
        })
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getAllFollowers(creatorId, params = {}) {
        return ApiService.getAll('creator/' + creatorId + '/followers', {
            params,
        })
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get dashaboard data
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getDashboardData() {
        return ApiService.get(`creator/${creator.value.id}/dashboard-data`)
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    /********************
     * ONBOARDING
     *******************/
    /**
     * Check if creator needs onboarding
     * @returns {boolean}
     */
    function needsOnboarding() {
        let onboardingDate = new Date(import.meta.env.VITE_ONBOARDING_CREATOR_DATE);

        let creatorCreatedAt = new Date(creator.value.created_at)

        return creatorCreatedAt.getTime() > onboardingDate.getTime()
    }

    /********************
     * BILLING
     *******************/

    async function getBillingInformation(creatorId) {
        return ApiService.get(`creator/${creatorId}/billing-information`)
        .then(({data}) => data)
        .catch(({data}) => {
            throw data.message
        })
    }

    async function updateBillingInformation(creatorId, billingId, _data) {
        return ApiService.put(`creator/${creatorId}/billing-information/${billingId}`, _data)
        .then(({data}) => data)
        .catch(({data}) => {
            throw data.message
        })
    }

    /********************
     * ID VERIFICATION
     *******************/
    /**
     * Check if creator needs ID verification
     * @returns {boolean}
     */
    function needsIdVerification() {
        let onboardingDate = new Date(import.meta.env.VITE_ONBOARDING_CREATOR_DATE);

        let creatorCreatedAt = new Date(creator.value.created_at)

        return creatorCreatedAt.getTime() > onboardingDate.getTime()
            && (
                creator.value.status === 'needs-confirm-id'
                || creator.value.status === 'failed-id'
            )
    }

    /********************
     * PUBLIC SEARCH
     *******************/
    /**
     * Check if creator needs ID verification
     * @returns {Promise<axios.AxiosResponse>}
     */
    function search(query = {}) {

        // init params
        let queryFilter = ''

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.get(`search/creator${queryFilter}`)
        .then(({data}) => data)
        .catch(({data}) => {
            throw data.message
        })
    }

    /**
     * Create ID Verification Session
     * @returns {Promise<object>}
     */
    async function getVerificationSession() {
        if (!creator.value?.id) {
            return Promise.reject('setup error: creator not set')
        }
        return ApiService.get(`creator/${creator.value.id}/id-verification-session`)
        .then(({data}) => data)
    }

    /**
     * Create ID Verification Session
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function createVerificationSession() {
        if (!creator.value?.id) {
            return Promise.reject('setup error: creator not set')
        }
        return ApiService.post(`creator/${creator.value.id}/id-verification-session`)
        .then(({data}) => data)
    }

    return {
        creator,
        hasCreatorSelected,

        setCreator,
        setModules,
        
        findCreatorBySlug,
        checkRegistrationPasswordSignature,
        createFirstPassword,
        plans,
        faqs,
        planById,
        getCreatorPosts,
        getSocialLinks,
        getLiked,

        getSubscribers,
        getAllSubscribers,
        getAllFollowers,
        getDashboardData,

        /********************
         * ONBOARDING
         *******************/
        needsOnboarding,

        /********************
         * MODULES
         *******************/
        modules,
        hasModule,

        /********************
         * BILLING
         *******************/
        getBillingInformation,
        updateBillingInformation,

        /********************
         * ID VERIFICATION
         *******************/
        needsIdVerification,
        createVerificationSession,
        getVerificationSession,

        /********************
         * MY SETTINGS
         *******************/
        requestedSettingsMenu,
        setRequestedSettingsMenu,

        /********************
         * PUBLIC SEARCH
         *******************/
        search,
    }
})