<template>
    <div class="post-comment-form w-100">
        <Form
            ref="myComment"
            :validation-schema="validationSchema"
            @submit="submitComment"
            @invalid-submit="onInvalidSubmit"
        >
            <div class="d-flex align-items-start gap-2">
                <BaseInput
                    ref="myInputRef"
                    v-model="contentValue"
                    name="content"
                    sync-v-model
                    type="textarea"
                    :placeholder="$t('creator.post.form.comment.add')"
                    class="border-0 p-0 "
                    :rows="1"
                    :max="5000"
                    :max-length="5000"
                    is-grouped
                    show-max-inline-mode
                    @input:keydown="handleKeydown"
                />

                <button class="btn btn-primary fw-light p-2 rounded-3 fs-7" :disabled="loading || contentValue.length < 3 || isLocked">
                    <span v-if="loading" class="spinner-grow spinner-grow-sm me-2" aria-hidden="true"></span>
                    <template v-if="$breakpoints.lgAndUp.value">
                        {{ $t('globals.sendComment') }}
                    </template>
                    <template v-else>
                        <i class="fa-regular fa-send"></i>
                    </template>
                </button>
            </div>
        </Form>
    </div>
</template>

<script setup>
import { Form } from 'vee-validate'
import BaseInput from "@/components/Form/BaseInput.vue";
import { object, string } from 'yup'
import NotificationService from "@/services/NotificationService";
import {onMounted, ref} from "vue";
import {usePreviewMode} from "@/plugins/Composables/usePreviewMode";
import {useUserStore} from "@/stores/user";
import {useCommentStore} from "@/stores/comment";
import {useI18n} from "vue-i18n";


const contentValue = ref('')
const myComment = ref(null)
const myInputRef = ref(null)

const {isPreviewMode} = usePreviewMode()

const {isGuest} = useUserStore()

const props = defineProps({
    postId: {
        type: Number,
        default: 0
    },
    isLocked: {
        type: Boolean,
        default: true,
    },
    shouldEmit: {
        type: Boolean,
        default: false,
    },
    shouldFocus: {
        type: Boolean,
        default: false,
    }
})
const emit = defineEmits(['new-comment'])

const {t} = useI18n()

/** Validation */
const validationSchema = object({
    content: string()
    .required(t('globals.validations.required'))
    .label('Message').min(3, t('globals.validations.min', {min: 3})).max(5000, t('globals.validations.max', {max: 5000})),
})

/** Methods */
function onInvalidSubmit({ errors }) {
    NotificationService.notifyUserError(errors)
}

function handleResize() {
    // let element = document.getElementById('post-comment-form-content')
    let element = myInputRef.value.input

    element.style.height = "46px";
    element.style.height = (element.scrollHeight + 10) + "px";
}
const handleKeydown = (e) => {
    // if (e.key === 'Enter' && !e.shiftKey) {
        // submitBtn.value?.click()
        // e.preventDefault()
    // }
    contentValue.value = contentValue.value.slice(0, (5000))
    setTimeout(() => { handleResize() }, 10);
}

const loading = ref(false)

const submitComment = (values)  => {

    loading.value = true

    // Preview mode?
    if (isPreviewMode()) {
        loading.value = false
        return;
    }


    if (props.shouldEmit) {
        myComment.value.resetForm()
        loading.value = false
        emit('new-comment', {
            content: values.content,
            user_id: useUserStore().user.id,
        })
        return ;
    }

    if (isGuest) {
        myComment.value.resetForm()
        loading.value = false
        emit('new-comment', null)
        return ;
    }

    // Send to API
    let data = ref({
        content: values.content,
        user_id: useUserStore().user.id,
    })

    useCommentStore()
    .addForPost(props.postId, data)
    .then((_comment) => {
        // Empty input
        myComment.value.resetForm()
        loading.value = false
        emit('new-comment', _comment)
    })
    .catch((error) => {
        NotificationService.notifyUserError(error)
    })
}

onMounted(() => {
    if (props.shouldFocus && myInputRef.value) {
        myInputRef.value.input.focus()
    }
})

</script>

<style lang="scss">
.post-comment-form {
    textarea {
        border: 0 !important;
        max-height: 200px;
        resize: none !important;

        overflow-y: auto;

        &:focus {
            box-shadow: none !important;
        }
    }
}
</style>
