import { authMiddleware } from '@/router/middleware/authMiddleware'
import { roleMiddleware } from '@/router/middleware/roleMiddleware'
import { checkOnboardingMiddleware } from '@/router/middleware/checkOnboardingMiddleware'
import RolesHelper from '@/helpers/Roles'
import KolAdminMenuConfig from '@/config/KolAdminMenuConfig'
import UserMenuConfig from '@/config/UserMenuConfig'

import i18n from '@/translation'

const { t } = i18n.global
const kolAdmin = [
    {
        path: '/my',
        component: () => import('@/components/Layout/UserLayout.vue'),
        props: {
            menu: KolAdminMenuConfig,
        },
        beforeEnter: [authMiddleware, roleMiddleware, checkOnboardingMiddleware],
        meta: {
            authorize: [
                // RolesHelper.Roles.SuperAdmin,
                RolesHelper.Roles.Creator,
                // RolesHelper.Roles.CreatorManager,
                RolesHelper.Roles.AccountManager,
            ],
        },
        children: [
            {
                path: '',
                redirect: '/my/feed',
            },
            {
                path: 'page',
                name: 'kol-admin-my-page',
                component: () => import('@/views/KolAdmin/MyPage.vue'),
            },
            // {
            //     path: 'firestore',
            //     name: 'kol-admin-my-firestore',
            //     component: () => import('@/components/StreamChatV2.vue'),
            // },
            {
                path: 'feed',
                children: [
                    {
                        path: '',
                        name: 'kol-admin-my-feed',
                        component: () => import('@/views/KolAdmin/Feed/CreatorFeed.vue'),
                    },
                    {
                        path: 'post/add',
                        name: 'kol-admin-post-add',
                        component: () => import('@/views/KolAdmin/Components/PostForm.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.post'),
                        },
                    },
                    {
                        path: 'post/edit/:postId',
                        name: 'kol-admin-post-edit',
                        component: () => import('@/views/KolAdmin/Components/PostForm.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.post'),
                        },
                    },
                ],
                meta: {
                    hasRightPanel: true,
                }
            },
            {
                path: 'account-verification',
                name: 'kol-account-verification',
                component: () => import('@/views/KolAdmin/AccountVerification/AccountVerificationIndex.vue'),
            },
            {
                path: 'account-verification/pending',
                name: 'kol-account-verification-pending',
                component: () => import('@/views/KolAdmin/AccountVerification/AccountVerificationPending.vue'),
            },
            {
                path: 'account-connect/welcome',
                name: 'kol-account-connect-welcome',
                component: () => import('@/views/KolAdmin/AccountVerification/AccountConnectWelcome.vue'),
            },
            {
                path: 'dashboard',
                name: 'kol-admin-dashboard',
                component: () => import('@/views/KolAdmin/Dashboard/Dashboard.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('creator.menu.dashboard'),
                },
            },
            {
                path: 'notification/:type',
                name: 'kol-admin-notification-redirector',
                component: () => import('@/views/User/NotificationRedirector.vue'),
            },
            {
                path: 'creators',
                props: true,
                name: 'kol-admin-creators-management',
                redirect: {name: 'kol-admin-my-creators'},
                component: () => import('@/views/KolAdmin/AccountManagerProfile/AccountManagerLayout.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('creator.menu.creators'),
                },
                children: [
                    {
                        path: 'list',
                        name: 'kol-admin-my-creators',
                        component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorsList.vue'),
                    },
                    {
                        path: 'posts',
                        name: 'kol-admin-my-creators-posts',
                        component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorsPosts.vue'),
                    },
                    {
                        path: 'edit/:id/profile',
                        component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorProfile.vue'),
                        props: true,
                        children: [
                            {
                                path: 'personal',
                                component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorPersonalInfo.vue'),
                                name: 'kol-admin-creator-profile-personal',
                            },
                            {
                                path: 'global-settings',
                                component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorGlobalSettings.vue'),
                                name: 'kol-admin-creator-profile-global-settings',
                            },
                            {
                                path: 'messenger-settings',
                                component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorMessengerSettings.vue'),
                                name: 'kol-admin-creator-profile-messenger-settings',
                            },
                            {
                                path: 'subscriptions/plans',
                                component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorPlans.vue'),
                                name: 'kol-admin-creator-profile-subscriptions-plans',
                            },
                            {
                                path: 'privacy-security/password',
                                component: () => import('@/views/KolAdmin/AccountManagerProfile/CreatorPassword.vue'),
                                name: 'kol-admin-creator-profile-privacy-security-password',
                            },
                        ],
                    },
                    // {
                    //     path: 'edit/:id',
                    //     name: 'kol-admin-my-creators-edit',
                    //     component: () => import('@/views/KolAdmin/AccountManagerProfile/Kols/Edit.vue'),
                    // },
                ]
            },
            {
                path: 'settings',
                component: () => import('@/views/KolAdmin/MySettings.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('creator.menu.mySettings'),
                    requestedMenu: '',
                },
                children: [
                    {
                        path: 'language',
                        name: 'kol-admin-settings-language',
                        component: () => import('@/views/User/Account/Language.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: '',
                        name: 'kol-admin-my-settings',
                        component: () => import('@/views/KolAdmin/MySettings/PersonalInfo.vue'),
                        props: true,
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'password',
                        name: 'kol-admin-settings-password',
                        component: () => import('@/views/KolAdmin/MySettings/NewPassword.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'socials',
                        name: 'kol-admin-settings-socials',
                        component: () => import('@/views/KolAdmin/MySettings/MySocialNetworks.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'modules',
                        name: 'kol-admin-settings-modules',
                        component: () => import('@/views/KolAdmin/MySettings/MyModule.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'messenger',
                        name: 'kol-admin-settings-messenger',
                        component: () => import('@/views/KolAdmin/MySettings/MyMessenger.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'availability',
                        name: 'kol-admin-settings-availability',
                        component: () => import('@/views/KolAdmin/MySettings/MyAvailability.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'notification',
                        name: 'kol-admin-settings-notification',
                        component: () => import('@/views/User/Account/NotificationManager.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'privacy',
                        name: 'kol-admin-settings-privacy',
                        component: () => import('@/views/KolAdmin/MySettings/MyPrivacy.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'plans',
                        name: 'kol-admin-settings-plans',
                        component: () => import('@/views/KolAdmin/MySettings/MyPlans.vue'),
                        meta: {transition: 'fade'}
                    },
                    {
                        path: 'private-video',
                        name: 'kol-admin-settings-private-video',
                        component: () => import('@/views/KolAdmin/MySettings/PrivateVideoCalls.vue'),
                        meta: {transition: 'fade'}
                    },
                ]
            },
            // {
            //     path: 'plan-prices',
            //     name: 'kol-admin-my-plan-prices',
            //     component: () => import('@/views/KolAdmin/MyPlans.vue'),
            // },
            {
                path: 'post/:id',
                name: 'kol-admin-post-view',
                component: () => import('@/views/KolAdmin/PostView.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: false,
                    breadcrumbBarTitle: t('user.menu.post'),
                },
            },
            {
                path: 'video/:userId/:eventId',
                name: 'kol-admin-video',
                component: () => import('@/views/KolAdmin/MyVideo.vue'),
            },
            {
                path: 'calendar',
                children: [
                    {
                        path: '',
                        name: 'kol-admin-my-calendar',
                        component: () => import('@/views/User/MyCalendar.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('calendar.global.calendar'),
                        },
                    },
                    {
                        name: 'kol-admin-calendar-add-event',
                        path: 'add/:type/:slot',
                        component: () => import('@/views/User/Calendar/AddEvent.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('calendar.global.calendar'),
                            backButton: 'kol-admin-my-calendar',
                        },
                    },
                    {
                        path: 'great/:eventId',
                        name: 'kol-admin-calendar-congrats',
                        component: () => import('@/views/User/Calendar/Great.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('calendar.global.calendar'),
                            backButton: 'kol-admin-my-calendar',
                        },
                    },
                ],
            },
            {
                path: 'workouts',
                children: [
                    {
                        path: '',
                        component: () => import('@/views/KolAdmin/Workouts/Workouts.vue'),
                        children: [
                            {
                                path: '',
                                children: [],
                                name: 'kol-admin-my-workouts',
                                component: () => import('@/views/KolAdmin/MyWorkouts.vue'),
                                props: {
                                    viewType: 'workouts',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('creator.menu.myWorkouts'),
                                },
                            },
                            {
                                path: 'create',
                                // path: 'create/:uuid?',
                                name: 'kol-admin-create-workout',
                                component: () => import('@/views/KolAdmin/CreateWorkout.vue'),
                                props: {
                                    viewType: 'workouts',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkouts'),
                                    backButton: 'kol-admin-my-workouts',
                                },
                            },
                            {
                                path: ':workoutId/edit',
                                name: 'kol-admin-edit-workout',
                                component: () => import('@/views/KolAdmin/EditWorkout.vue'),
                                props: {
                                    viewType: 'workouts',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkouts'),
                                    backButton: 'kol-admin-my-workouts',
                                },
                            },
                            {
                                path: ':workoutId/episodes',
                                name: 'kol-admin-view-workout-episodes',
                                component: () => import('@/views/KolAdmin/ViewWorkoutEpisodes.vue'),
                                props: {
                                    viewType: 'workouts',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkouts'),
                                    backButton: 'kol-admin-my-workouts',
                                },
                            },
                            {
                                path: ':workoutId/episodes/create',
                                // path: ':workoutId/episodes/create/:uuid?',
                                name: 'kol-admin-create-workout-episode',
                                component: () => import('@/views/KolAdmin/CreateWorkoutEpisode.vue'),
                                props: {
                                    viewType: 'workouts',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkouts'),
                                    backButton: 'kol-admin-my-workouts',
                                },
                            },
                            {
                                path: ':workoutId/episodes/:episodeId/edit',
                                name: 'kol-admin-edit-workout-episode',
                                component: () => import('@/views/KolAdmin/EditWorkoutEpisode.vue'),
                                props: {
                                    viewType: 'workouts',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkouts'),
                                    backButton: 'kol-admin-my-workouts',
                                },
                            },
                            {
                                path: ':workoutId/episodes/create-multiple',
                                name: 'kol-admin-create-multiple-workout-episodes',
                                component: () => import('@/views/KolAdmin/CreateMultipleEpisodes.vue'),
                                props: {
                                    viewType: 'workouts',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkouts'),
                                    backButton: 'kol-admin-my-workouts',
                                },
                            },
                            {
                                path: 'program',
                                name: 'kol-admin-my-workout-programs',
                                component: () => import('@/views/KolAdmin/Workouts/MyWorkoutPrograms.vue'),
                                props: {
                                    viewType: 'workout-programs',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('creator.menu.myWorkoutPrograms'),
                                },
                            },
                            {
                                path: 'program/create',
                                name: 'kol-admin-create-workout-program',
                                component: () => import('@/views/KolAdmin/Workouts/CreateWorkoutProgram.vue'),
                                props: {
                                    viewType: 'workout-programs',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkoutPrograms'),
                                    backButton: 'kol-admin-my-workout-programs',
                                },
                            },
                            {
                                path: 'program/:workoutProgramId',
                                name: 'kol-admin-edit-workout-program',
                                component: () => import('@/views/KolAdmin/Workouts/EditWorkoutProgram.vue'),
                                props: {
                                    viewType: 'workout-programs',
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.myWorkoutPrograms'),
                                    backButton: 'kol-admin-my-workout-programs',
                                },
                            },
                        ],
                    },
                    {
                        path: ':workoutId/preview',
                        name: 'kol-admin-preview-workout',
                        component: () => import('@/views/User/Masterclass/Workout/WorkoutView.vue'),
                        props: {
                            isPreview: true,
                        },
                    },
                ],
            },
            {
                path: 'masterclass',
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'kol-admin-my-masterclass',
                        component: () => import('@/views/KolAdmin/Masterclass/MyMasterclass.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.masterclasses'),
                        },
                    },
                    {
                        path: 'create',
                        // path: 'create/:uuid?',
                        name: 'kol-admin-create-masterclass',
                        component: () => import('@/views/KolAdmin/Masterclass/CreateMasterclass.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.masterclasses'),
                            backButton: 'kol-admin-my-masterclass',
                        },
                    },
                    {
                        path: ':masterclassId/edit',
                        name: 'kol-admin-edit-masterclass',
                        component: () => import('@/views/KolAdmin/Masterclass/EditMasterclass.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.masterclasses'),
                            backButton: 'kol-admin-my-masterclass',
                        },
                    },
                    {
                        path: ':masterclassId/episodes',
                        name: 'kol-admin-view-masterclass-episodes',
                        component: () => import('@/views/KolAdmin/Masterclass/ViewMasterclassEpisodes.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.masterclasses'),
                            backButton: 'kol-admin-my-masterclass',
                        },
                    },
                    {
                        path: ':masterclassId/episodes/create',
                        // path: ':masterclassId/episodes/create/:uuid?',
                        name: 'kol-admin-create-masterclass-episode',
                        component: () => import('@/views/KolAdmin/Masterclass/CreateMasterclassEpisode.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.masterclasses'),
                            backButton: 'kol-admin-my-masterclass',
                        },
                    },
                    {
                        path: ':masterclassId/episodes/:episodeId/edit',
                        name: 'kol-admin-edit-masterclass-episode',
                        component: () => import('@/views/KolAdmin/Masterclass/EditMasterclassEpisode.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.masterclasses'),
                            backButton: 'kol-admin-my-masterclass',
                        },
                    },
                ],
            },
            {
                path: 'podcasts',
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'kol-admin-my-podcasts',
                        component: () => import('@/views/KolAdmin/Podcasts/PodcastIndex.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.podcasts'),
                        },
                    },
                    {
                        path: 'setup',
                        name: 'kol-admin-setup-podcasts',
                        component: () => import('@/views/KolAdmin/Podcasts/PodcastSetup.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.podcasts'),
                            backButton: 'kol-admin-my-podcasts',
                        },
                    },
                    {
                        path: ':podcastId/setup',
                        name: 'kol-admin-edit-setup-podcasts',
                        component: () => import('@/views/KolAdmin/Podcasts/PodcastSetup.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.podcasts'),
                            backButton: 'kol-admin-my-podcasts',
                        },
                    },
                    {
                        path: 'create',
                        name: 'kol-admin-create-podcast-episode',
                        component: () => import('@/views/KolAdmin/Podcasts/PodcastEpisode.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.podcasts'),
                            backButton: 'kol-admin-my-podcasts',
                        },
                    },
                    {
                        path: ':episodeId/edit',
                        name: 'kol-admin-edit-podcasts-episode',
                        component: () => import('@/views/KolAdmin/Podcasts/PodcastEpisode.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.podcasts'),
                            backButton: 'kol-admin-my-podcasts',
                        },
                    },
                ],
            },
            {
                path: 'messages',
                name: 'kol-admin-messages',
                component: () => import('@/views/KolAdmin/Messages.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: false,
                    breadcrumbBarTitle: t('user.menu.messages'),
                },
            },
            {
                path: 'medias',
                children: [
                    {
                        path: '',
                        name: 'kol-admin-medias-management',
                        component: () => import('@/views/KolAdmin/Medias/MediaManagement.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.medias'),
                        },
                    },
                    {
                        path: 'upload',
                        name: 'kol-admin-medias-upload',
                        component: () => import('@/views/KolAdmin/Medias/MediaUpload.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.medias'),
                            backButton: 'kol-admin-medias-management',
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'kol-admin-medias-edit',
                        component: () => import('@/views/KolAdmin/Medias/MediaEdit.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.medias'),
                            backButton: 'kol-admin-medias-management',
                        },
                    },
                ],
            },
            {
                path: 'gallery',
                name: 'kol-my-gallery',
                component: () => import('@/views/User/MyGallery.vue'),
                props: {
                    isCreator: true,
                },
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('creator.menu.gallery'),
                },
            },
            {
                path: 'recipes',
                component: () => import('@/views/KolAdmin/Recipes/RecipesLayout.vue'),
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'kol-admin-my-recipes',
                        component: () => import('@/views/KolAdmin/Recipes/MyRecipes.vue'),
                        props: {
                            isCreator: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                        },
                    },
                    {
                        path: 'select',
                        name: 'kol-admin-select-recipes',
                        component: () => import('@/views/KolAdmin/Recipes/SelectRecipes.vue'),
                        props: {
                            isCreator: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'kol-admin-my-recipes',
                        },
                    },
                    {
                        path: ':recipeId/preview',
                        name: 'kol-admin-preview-recipe',
                        component: () => import('@/views/KolAdmin/Recipes/ViewRecipe.vue'),
                        props: {
                            isCreator: true,
                            preview: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'kol-admin-my-recipes',
                        },
                    },
                    {
                        path: ':recipeId',
                        name: 'kol-admin-view-recipe',
                        component: () => import('@/views/KolAdmin/Recipes/ViewRecipe.vue'),
                        props: {
                            isCreator: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'kol-admin-my-recipes',
                        },
                    },
                    // PRIVATE RECIPES
                    {
                        path: 'private',
                        children: [
                            {
                                path: '',
                                children: [],
                                name: 'kol-admin-my-private-recipes',
                                component: () => import('@/views/KolAdmin/Recipes/Private/KolPrivateRecipesIndex.vue'),
                                props: {
                                    isCreator: true,
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('user.menu.recipes'),
                                },
                            },
                        ],
                    },
                ],
            },
            // PRIVATE RECIPES
            {
                path: 'recipes/private',
                component: () => import('@/views/KolAdmin/Recipes/RecipesLayout.vue'),
                children: [
                    {
                        path: 'show/:recipeId',
                        children: [],
                        name: 'kol-admin-private-recipes-show',
                        component: () => import('@/views/User/Recipes/PrivateRecipes/CreatorPrivateRecipeShow.vue'),
                        // component: () => import('@/views/KolAdmin/Recipes/Private/KolPrivateRecipesCreateTEST.vue'),
                        props: {
                            isCreator: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'kol-admin-my-private-recipes',
                        },
                    },
                    {
                        path: 'create',
                        children: [],
                        name: 'kol-admin-private-recipes-create',
                        component: () => import('@/views/KolAdmin/Recipes/Private/KolPrivateRecipesCreate.vue'),
                        // component: () => import('@/views/KolAdmin/Recipes/Private/KolPrivateRecipesCreateTEST.vue'),
                        props: {
                            isCreator: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'kol-admin-my-private-recipes',
                        },
                    },
                    {
                        path: 'edit/:recipeId',
                        children: [],
                        name: 'kol-admin-private-recipes-edit',
                        component: () => import('@/views/KolAdmin/Recipes/Private/KolPrivateRecipesEdit.vue'),
                        // component: () => import('@/views/KolAdmin/Recipes/Private/KolPrivateRecipesCreateTEST.vue'),
                        props: {
                            isCreator: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'kol-admin-my-private-recipes',
                        },
                    },
                ],
            },
            {
                path: 'live/:eventId?',
                name: 'kol-admin-live',
                component: () => import('@/views/KolAdmin/Live.vue'),
                meta: {
                    hasTopBar: false,
                    hasTitleBar: false,
                    hasBottomBar: false,
                    breadcrumbBarTitle: 'Live',
                }
            },
            // {
            //     path: 'live-ivs/:eventId?',
            //     name: 'kol-admin-live-ivs',
            //     component: () => import('@/views/KolAdmin/LiveV2.vue'),
            //     meta: {
            //         hasTopBar: false,
            //         hasTitleBar: false,
            //         hasBottomBar: false,
            //         breadcrumbBarTitle: 'Live',
            //     }
            // },
            {
                path: 'live-events',
                children: [
                    {
                        path: '',
                        name: 'kol-admin-my-live-events',
                        component: () => import('@/views/KolAdmin/LiveEvents/LiveEventsIndex.vue'),
                        meta: {
                            hasTopBar: false,
                            hasTitleBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.liveEvents'),
                        }
                    },
                    {
                        path: 'create',
                        name: 'kol-admin-create-live-event',
                        component: () => import('@/views/KolAdmin/LiveEvents/LiveEventsCreate.vue'),
                        meta: {
                            hasTopBar: false,
                            hasTitleBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.liveEvents'),
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'kol-admin-edit-live-event',
                        component: () => import('@/views/KolAdmin/LiveEvents/LiveEventsEdit.vue'),
                        meta: {
                            hasTopBar: false,
                            hasTitleBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.liveEvents'),
                        }
                    },
                    {
                        path: ':uuid/stream',
                        name: 'kol-admin-live-event-stream',
                        component: () => import('@/views/KolAdmin/LiveEvents/LiveEventStream.vue'),
                        meta: {
                            hasTopBar: false,
                            hasTitleBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.liveEvents'),
                        }
                    }
                ]
            },
            {
                path: 'subscribers',
                name: 'kol-my-subscribers',
                component: () => import('@/views/KolAdmin/MySubscribers.vue'),
                props: {
                    isCreator: true,
                },
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('creator.menu.subscribers'),
                },
            },
            {
                path: 'followers',
                name: 'kol-my-followers',
                component: () => import('@/views/KolAdmin/MyFollowers.vue'),
                props: {
                    isCreator: true,
                },
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('creator.menu.followers'),
                },
            },
        ],
    },
    {
        path: '/preview',
        name: 'preview',
        component: () => import('@/components/Layout/UserLayout.vue'),
        props: {
            menu: UserMenuConfig,
        },
        beforeEnter: [authMiddleware, roleMiddleware],
        meta: {
            authorize: [
                RolesHelper.Roles.Creator,
                RolesHelper.Roles.User
            ],
        },
        children: [
            {
                path: 'dashboard',
                name: 'preview-user-dashboard',
                component: () => import('@/views/User/Dashboard.vue'),
            },
            {
                path: 'posts/:id',
                name: 'preview-user-post-view',
                component: () => import('@/views/User/Posts/PostView.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: false,
                    breadcrumbBarTitle: t('user.menu.post'),
                    backButton: 'preview-user-dashboard',
                },
            },
            {
                path: 'account',
                name: 'preview-user-account',
                component: () => import('@/views/User/Account.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('user.menu.myAccount'),
                    backButton: 'preview-user-dashboard',
                },
            },
            {
                path: 'video',
                name: 'preview-user-video',
                component: () => import('@/views/KolAdmin/MyVideo.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: false,
                    breadcrumbBarTitle: t('messenger.user.privateCall.title'),
                    backButton: 'preview-user-dashboard',
                },
            },
            {
                path: 'workouts',
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'preview-user-workouts',
                        component: () => import('@/views/User/Masterclass/Workout/WorkoutList.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.workouts'),
                            backButton: 'preview-user-dashboard',
                        },
                    },
                    {
                        path: ':workoutId',
                        name: 'preview-user-view-workouts',
                        component: () => import('@/views/User/Masterclass/Workout/WorkoutView.vue'),
                        props: {
                            isPreview: true,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.workouts'),
                            backButton: 'preview-user-workouts',
                        },
                    },
                    {
                        path: 'workout-program',
                        name: 'preview-user-workout-program',
                        component: () => import('@/views/User/WorkoutProgram/WorkoutProgramList.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.workouts.subMenu.workoutProgram'),
                            backButton: 'preview-user-dashboard',
                        },
                    },
                    {
                        path: 'workout-program/:workoutProgramId',
                        name: 'preview-user-view-workout-program',
                        component: () => import('@/views/User/WorkoutProgram/WorkoutProgramView.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.workouts'),
                            backButton: 'preview-user-workout-program',
                        },
                    },
                ],
            },
            {
                path: 'masterclass',
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'preview-user-masterclass',
                        component: () => import('@/views/User/Masterclass/MasterclassList.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.masterclasses'),
                            backButton: 'preview-user-dashboard',
                        },
                    },

                    {
                        path: ':masterclassId',
                        component: () => import('@/views/User/Masterclass/MasterclassViewV2.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.masterclasses'),
                            backButton: 'preview-user-masterclass',
                        },
                        children: [
                            {
                                path: '',
                                name: 'preview-user-view-masterclass',
                                component: () => import('@/views/User/Masterclass/_partials/MasterclassViewStart.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.masterclasses'),
                                    backButton: 'preview-user-masterclass',
                                },
                                props: {
                                    masterclass: {
                                        type: Object,
                                        default: () => {}
                                    },
                                    loading: {
                                        type: Boolean,
                                        default: true
                                    },
                                }
                            },
                            {
                                path: 'episode/:episodeId',
                                name: 'preview-user-view-masterclass-episode',
                                component: () => import('@/views/User/Masterclass/_partials/MasterclassViewEpisode.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.masterclasses'),
                                    backButton: 'preview-user-masterclass',
                                },
                                props: {
                                    masterclass: {
                                        type: Object,
                                        default: () => {}
                                    },
                                    episodeId: {
                                        type: Number,
                                        default: null
                                    },
                                    loading: {
                                        type: Boolean,
                                        default: true
                                    },
                                }
                            },
                        ]
                    },
                ],
            },
            {
                path: 'podcasts',
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'preview-user-podcasts',
                        component: () => import('@/views/User/Podcasts/PodcastIndex.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.podcasts'),
                        },
                    },
                    {
                        path: ':episodeId',
                        name: 'preview-user-view-podcast',
                        component: () => import('@/views/User/Podcasts/PodcastEpisode.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('creator.menu.podcasts'),
                            backButton: 'user-podcasts',
                        },
                    },
                ],
            },
            {
                path: 'calendar',
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'preview-user-my-calendar',
                        component: () => import('@/views/User/MyCalendar.vue'),
                    },
                    {
                        path: 'call',
                        name: 'preview-user-private-video-call-event',
                        component: () => import('@/views/User/Calendar/PrivateVideoCallEvent.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.myCalendar'),
                        },
                    },
                ],
            },
            {
                path: 'recipes',
                children: [
                    {
                        path: '',
                        children: [],
                        name: 'preview-user-recipes',
                        component: () => import('@/views/KolAdmin/Recipes/MyRecipes.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'preview-user-dashboard',
                        },
                    },
                    {
                        path: ':recipeId',
                        name: 'preview-user-view-recipe',
                        component: () => import('@/views/KolAdmin/Recipes/ViewRecipe.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'preview-user-recipes',
                        },
                    },
                    {
                        path: 'private/:recipeId',
                        name: 'preview-user-show-private-recipe',
                        component: () => import('@/views/User/Recipes/PrivateRecipes/CreatorPrivateRecipeShow.vue'),
                        props: {
                            isCreator: false,
                        },
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.recipes'),
                            backButton: 'preview-user-recipes',
                        },
                    },
                ],
            },
            {
                path: 'favorites',
                name: 'preview-user-favorites',
                component: () => import('@/views/User/Favorites.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('user.menu.myFavorites'),
                    backButton: 'preview-user-dashboard',
                },
            },
            {
                path: 'messages',
                name: 'preview-user-messages',
                component: () => import('@/views/User/Messages.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: false,
                    breadcrumbBarTitle: t('user.menu.messages'),
                    backButton: 'preview-user-dashboard',
                },
            },
            {
                path: 'progress',
                name: 'preview-user-progress',
                component: () => import('@/views/User/MyProgress.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('user.menu.myProgress'),
                    backButton: 'preview-user-dashboard',
                },
            },
            {
                path: 'gallery',
                name: 'preview-user-gallery',
                component: () => import('@/views/User/MyGallery.vue'),
                meta: {
                    hasTopBar: false,
                    hasBottomBar: true,
                    breadcrumbBarTitle: t('user.menu.myGallery'),
                    backButton: 'preview-user-dashboard',
                },
            },
            {
                path: 'live-events',
                children: [
                    {
                        path: '',
                        name: 'preview-user-live-events',
                        component: () => import('@/views/User/LiveEvents/LiveEventsIndex.vue'),
                        meta: {
                            hasTopBar: false,
                            hasTitleBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.liveEvents'),
                        }
                    },
                    {
                        path: ':uuid',
                        name: 'preview-user-live-event-view',
                        component: () => import('@/views/User/LiveEvents/LiveEventsView.vue'),
                        meta: {
                            hasTopBar: false,
                            hasTitleBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('creator.menu.liveEvents'),
                        }
                    }
                ]
            },
        ],
    },
    {
        path: '/my/onboarding',
        component: () => import('@/components/Layout/UserOnboardingLayout.vue'),
        // beforeEnter: [authMiddleware, roleMiddleware, checkOnboardingMiddleware(true)],
        beforeEnter: [authMiddleware, roleMiddleware],
        meta: {
            authorize: [RolesHelper.Roles.Creator],
        },
        children: [
            {
                path: '',
                name: 'kol-admin-onboarding',
                component: () => import('@/views/KolAdmin/Onboarding.vue'),
            },
        ],
    },
    {
        path: '/my/live/:eventId?',
        name: 'kol-admin-live-mobile',
        component: () => import('@/views/KolAdmin/Live.vue'),
        meta: {
            hasTopBar: false,
            hasTitleBar: false,
            hasBottomBar: false,
            breadcrumbBarTitle: 'Live',
        }
    },
    // {
    //     path: '/my/live-ivs/:eventId?',
    //     name: 'kol-admin-live-ivs-mobile',
    //     component: () => import('@/views/KolAdmin/LiveV2.vue'),
    //     meta: {
    //         hasTopBar: false,
    //         hasTitleBar: false,
    //         hasBottomBar: false,
    //         breadcrumbBarTitle: 'Live',
    //     }
    // },
]

export default kolAdmin