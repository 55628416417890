import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'

export const useCommentStore = defineStore('comment', () => {
    const errors = ref([])

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all comments on specific post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function listForPost(postId, cursor = '') {
        // Get data
        let queryPage = (cursor !== "") ? '?cursor=' + cursor : ''
        return ApiService.get('post/' + postId + '/comments' + queryPage)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add a comment on specific post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function addForPost(postId, data) {
        // Get data
        return ApiService.post('post/' + postId + '/comments', data.value)
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }


    /**
     * Like/Unlike a post
     * @returns {Promise<axios.AxiosResponse>}
     */
    function like(commentId) {
        return ApiService.post('comments/' + commentId + '/toggle-like')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all comments on specific media gallery
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function listForGallery(galleryId, cursor = '') {
        // Get data
        let queryPage = (cursor !== "") ? '?cursor=' + cursor : ''
        return ApiService.get('gallery/' + galleryId + '/comments' + queryPage)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add a comment on specific gallery
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function addForGallery(galleryId, data) {
        // Get data
        return ApiService.post('gallery/' + galleryId + '/comments', data.value)
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    function destroyForPost(postId, commentId) {
        return ApiService.delete(`post/${postId}/comments/${commentId}`)
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }
    function editForPost(postId, commentId, data) {
        return ApiService.patch(
            `post/${postId}/comments/${commentId}`,
            {...data}
        )
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add a reply on specific comment
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function addForComment(commentId, data) {
        // Get data
        return ApiService.post('comments/' + commentId + '/replies', data)
            .then((result) => {
                return result.data
            })
            .catch((e) => {
                throw e
            })
    }

    /**
     * Delete a reply on specific comment
     * @returns {Promise<axios.AxiosResponse>}
     */
    function destroyForComment(commentId, replyId) {
        return ApiService.delete(`comments/${commentId}/replies/${replyId}`)
            .then((result) => {
                return result.data
            })
            .catch((e) => {
                throw e
            })
    }

    /**
     * edit a reply on specific comment
     * @returns {Promise<axios.AxiosResponse>}
     */
    function editForComment(commentId, replyId, data) {
        return ApiService.patch(
            `comments/${commentId}/replies/${replyId}`,
            {...data}
        )
        .then((result) => {
            return result.data
        })
        .catch((e) => {
            throw e
        })
    }

    return {
        errors,

        listForPost,
        addForPost,
        destroyForPost,
        editForPost,

        addForComment,
        destroyForComment,
        editForComment,

        listForGallery,
        addForGallery,
        setErrors,

        like,
    }
})
