<template>
    <div v-if="item" class="d-flex align-items-start justify-content-center flex-grow-1 my-2">
        <template v-if="postIsLocked && item.teaser_video !== null && (isImage || isAudio || isVideo)">
            <div class="flex-column">
                <ContentUnlockerButton class="mb-3" @click="locked"></ContentUnlockerButton>

                <PostVideoMedia
                    :id="`${item.id}`"
                    :key="`post-video-${item.id}${isView?'-viewer':''}`"
                    :pause-on-hide="pauseOnHide"
                    :media="item.media"
                    :is-view="isView"
                    :video="video"
                    :poster="video_poster"
                    :teaser-poster="item.media?.poster"
                    :is-teaser-video="isVideo && item.teaser_video !== null ? true : false"
                    :post-is-locked="postIsLocked"
                    :checked-visibility="item.checked_visibility"
                />
            </div>
        </template>
        <template v-else-if="isImage">
            <PostImageMedia
                :id="`${item.id}`"
                :key="`post-image-${item.id}`"
                :media="item.media"
                :post-is-locked="postIsLocked"
                :checked-visibility="item.checked_visibility"
            />
        </template>
        <template v-else-if="isAudio">
            <PostAudioMedia
                :id="`${item.id}`"
                :key="`post-audio-${item.id}`"
                :pause-on-hide="pauseOnHide"
                :media="item.media"
                :post-is-locked="postIsLocked"
                :checked-visibility="item.checked_visibility"
            />
        </template>
        <template v-else-if="isVideo || (!isImage && !isAudio && !isVideo)">
            <PostVideoMedia
                :id="`${item.id}`"
                :key="`post-video-${item.id}${isView?'-viewer':''}`"
                :pause-on-hide="pauseOnHide"
                :media="item.media"
                :is-view="isView"
                :progress="progress"
                :video="video"
                :poster="video_poster"
                :teaser-poster="item.media?.poster"
                :is-teaser-video="isVideo && item.teaser_video !== null ? true : false"
                :post-is-locked="postIsLocked"
                :checked-visibility="item.checked_visibility"
            />
        </template>
    </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, ref, toRaw} from "vue";
import { useI18n } from 'vue-i18n'
import PostImageMedia from "@/views/Common/Posts/Media/PostImageMedia.vue";
import PostAudioMedia from "@/views/Common/Posts/Media/PostAudioMedia.vue";
import PostVideoMedia from "@/views/Common/Posts/Media/PostVideoMedia.vue";
import ContentUnlockerButton from "@/components/Buttons/ContentUnlockerButton.vue";
import { useUserStore } from "@/stores/user";
import { useRegistrationCtaModalStore } from "@/stores/registrationCtaModal";


const { t } = useI18n()

const props = defineProps({
    item: {
        type: Object,
        default: () => ({
            id: 0,
            media: {
                original: false,
                thumb: false,
                media_type: ""
            },
            checked_visibility: 'blur',
        })
    },
    isView: {
        type: Boolean,
        default: false,
    },
    postIsLocked: {
        type: Boolean,
        default: false,
    },
    pauseOnHide: {
        type: Boolean,
        default: false,
    },
})

const isImage = computed(() => {
    return Object.prototype.hasOwnProperty.call(props.item.media, "media_type")
        && props.item.media.media_type !== null
        && (props.item.media.media_type?.startsWith('image/') || props.item.media.media_type?.startsWith('locked_image/'))
})
const isAudio = computed(() => {
    return Object.prototype.hasOwnProperty.call(props.item.media, "media_type")
        && props.item.media.media_type !== null
        && (props.item.media.media_type?.startsWith('audio/') || props.item.media.media_type?.startsWith('locked_audio/'))
})
const isVideo = computed(() => {
    return (
            Object.prototype.hasOwnProperty.call(props.item.media, "media_type")
        && props.item.media.media_type !== null
        && (
            props.item.media.media_type?.startsWith('video/')
            || props.item.media.media_type?.startsWith('locked_video/')
        )
    )
        || (props.item.video !== '' && props.item.video !== null && props.item.video !== undefined)
})

const video = computed(() => {
    return (props.postIsLocked && props.item.teaser_video !== null) ? props.item.teaser_video : props.item.video
})

const video_poster = computed(() => {
    return (props.postIsLocked && props.item.teaser_video_poster !== null) ? props.item.teaser_video_poster : props.item.video_poster
})

const progress = computed(() => {
    return (props.postIsLocked && props.item.teaser_video_poster !== null) ? props.item.teaser_video_progress : props.item.video_progress
})

function locked(){
    if (useUserStore().cannot('listen-post-audio')) {
        useRegistrationCtaModalStore().showModal()
        return false
    }
}

onMounted(() => {
})

</script>
