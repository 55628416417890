<template>
    <div class="d-flex flex-grow-1">
        <div v-if="isLocked" class="position-relative overflow-hidden w-100 pe-4 media cursor-pointer">
            <div class="d-flex align-items-center" style="height: 100px" @click.prevent="lockedAudio">
                <a href="javascript:void(0)" class="btn-play-pause d-block px-2" style="color: inherit;">
                    <i class="fa-regular fa-play-circle fa-2xl"></i>
                </a>
                <div class="audio-player lockedAudio flex-grow-1"></div>
            </div>
            <span v-if="postIsLocked" class="position-absolute start-50 top-50 translate-middle">
                <ContentUnlockerButton></ContentUnlockerButton>
            </span>
        </div>
        <div v-else class="flex-grow-1 pe-4 media">
            <AudioPlayer
                :pause-on-hide="pauseOnHide"
                :src="media.original"
            />
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, toRaw} from "vue";
import AudioPlayer from '@/components/AudioPlayer.vue'
import {useUserStore} from "@/stores/user";
import {useRegistrationCtaModalStore} from "@/stores/registrationCtaModal";
import ContentUnlockerButton from "@/components/Buttons/ContentUnlockerButton.vue";

const props = defineProps({
    id: {
        type: String,
        default: '0'
    },
    media: {
        type: Object,
        default: () => ({
            original: false,
            thumb: false
        })
    },
    checkedVisibility: {
        type: String,
        default: 'blur'
    },
    pauseOnHide: {
        type: Boolean,
        default: false,
    },
    postIsLocked: {
        type: Boolean,
        default: false,
    },
    noAction: {
        type: Boolean,
        default: false,
    },
})

const isLocked = computed(() => {
    return props.media.media_type.startsWith('locked')
})

function lockedAudio(){
    if (props.noAction) {
        return
    }
    if (useUserStore().cannot('listen-post-audio')) {
        useRegistrationCtaModalStore().showModal()
        return false
    }
}


onMounted(() => {
})
</script>


<style scoped lang="scss">
.media {
    max-height: 200px;
    position: relative;
}
.lockedAudio {
    height: 100px;
    background: url('/images/audio.png') repeat-x;
    background-origin: content-box;
}
.playLockedAudio {
    padding-left: 6px;
    border: 3px solid black;
    width: 37px;
    height: 37px;
    line-height: 32px;
    font-size: 14px;
}
</style>
