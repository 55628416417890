import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import {computed, ref} from 'vue'
import {QueryParamBuilder} from "@/helpers/QueryParamBuilder";

const DEFAULT_REPOST = {
    link: null
}

export const usePostStore = defineStore('post', () => {
    const errors = ref([])
    const post_links = ref([])

    const repost = ref({
        ...DEFAULT_REPOST
    })

    const isReposting = computed(() => repost.value.link !== null)

    function resetRepost() {
        repost.value = {...DEFAULT_REPOST}
    }

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all posts
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(creatorId, cursor = '', postId = 0, global = false) {
        // Get data
        // let query =
        //     postId > 0
        //         ? 'creator/' + creatorId + '/posts/' + postId
        //         : cursor !== ''
        //             ? 'creator/' + creatorId + '/posts?cursor=' + cursor
        //             : 'creator/' + creatorId + '/posts'
        // let query = 'posts';


        let query = {
            'per_page': 3
        }
        let queryFilter = ''

        if (cursor != null) {
            query.cursor = cursor
        }
        if (postId > 0) {
            queryFilter += `/${postId}`
        }

        if (global) {
            query.global = 1
        }
        queryFilter += QueryParamBuilder.encode(query)

        return ApiService.get(`creator/${creatorId}/posts${queryFilter}`)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all posts
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function listUnreleased(creatorId, cursor = '') {

        let url = 'creator/' + creatorId + '/future-posts';
        if (cursor !== '') {
            url += `?cursor=${cursor}`
        }

        return ApiService.get(url)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add a post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function add(values) {
        return ApiService.post('posts', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(postId, values) {
        return ApiService.postFormData('posts/' + postId, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Like/Unlike a post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function like(postId) {
        return ApiService.post('posts/' + postId + '/toggle-like')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function likedPosts(cursor = '') {
        let query = 'liked/post?cursor=' + cursor
        return ApiService.get(query)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function answerPoll(pollId, answerId) {
        return ApiService.post(`poll/${pollId}/answer/${answerId}`)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(postId) {
        return ApiService.delete('posts/' + postId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Toggle Pin a post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function togglePin(postId, is_pinned = false) {
        return ApiService
        .patch(
            `posts/${postId}/toggle-pinned`,
            {is_pinned}
        )
        .then((data) => {
            return data
        })
        .catch(({ data }) => {
            throw data.message
        })
    }

    /**
     * Get preview of a link
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getPreviewLink(_url) {

        // Check if link already scraped
        let isExists = post_links.value.find((item) => item.url === _url)

        if (isExists) {
            return isExists
        }
        else {
            return ApiService
                .post(`posts/link/preview`, {url: _url})
                .then((data) => {
                    post_links.value.push({url: _url, content: data.data})
                    return {url: _url, content: data.data}
                })
                .catch(({ data }) => {
                    throw data.message
                })
        }
    }

    /**
     * delete preview of a link
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function deletePreviewLink(postId, postLinkId) {
        return ApiService
            .delete(`posts/${postId}/link/${postLinkId}`)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add a view to post
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function addView(postId) {
        // Use beacon to not wait for response
        return ApiService
        .sendPostBeacon(`posts/${postId}/add-view`)
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return {data}
        })
        .catch((e) => {
            throw e
        })
    }

    return {
        errors,

        repost,
        isReposting,
        resetRepost,

        list,
        listUnreleased,
        add,
        update,
        like,
        softDelete,
        setErrors,
        likedPosts,
        answerPoll,
        togglePin,
        getPreviewLink,
        deletePreviewLink,

        addView,
    }
})
