<template>
    <component
        :is="tag"
        class=""
        :class="{
            title: true,
            'title-dark': textColor === 'dark',
            'title-light': textColor === 'light',
        }"
    >
        <div>
            <i v-if="!noStar" :class="`fa-${starType} fa-star fs-${fontSize} text-secondary me-2`"></i>
            <slot />
        </div>
    </component>
</template>

<script setup>
const { tag, textColor, starColorClass, noStar } = defineProps({
    tag: {
        type: String,
        default: 'h2',
    },
    textColor: {
        type: String,
        default: 'dark',
    },
    fontSize: {
        type: String,
        default: '6',
    },
    starType: {
        type: String,
        default: 'solid',
    },
    starColorClass: {
        type: String,
        default: 'title-icon-default-color',
    },
    noStar: {
        type: Boolean,
        default: false
    }
})

let starIconClasses = {
    'title-icon': true,
}

starIconClasses[starColorClass] = true
</script>

<style scoped>
.title {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    /*font-size: 1.375rem;*/
    /*line-height: 1.375rem;*/
    text-align: left;
}
h1.title {
    font-size: 1.675rem;
    line-height: 1.675rem;
}
h2.title {
    font-size: 1.375rem;
    line-height: 1.375rem;
}
.title-icon {
    margin-right: 0.5rem;
}
.title-icon-default-color {
    color: var(--title-star-color);
}
.title-dark {
    color: var(--title-text-color-dark);
}
.title-light {
    color: var(--title-text-color-light);
}
</style>
