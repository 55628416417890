<script setup>
import { computed } from 'vue'
import dayjs from 'dayjs/esm/index.js'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const year = computed(() => dayjs().format('YYYY'))
</script>

<template>
    <footer class="mt-5 py-4">

        <div class="row mt-2">
            <div class="col-12 text-center text-black fw-bold">
                <span>
                    Powered with <i class="fa-solid fa-heart text-danger"></i> by Seablue Luxembourg
                </span>
            </div>
            <div class="col-12 text-center text-body-tertiary">
                <small>
                    {{ year }} © IKONSTAR. {{ t('globals.allRightsReserved') }}
                </small>
            </div>
        </div>
    </footer>
</template>
