<template>
    <div>
        <div
            v-if="
                videoUrl === 'processing' ||
                videoUrl === 'pending' ||
                videoUrl === 'failed'
            ">
            {{ $t(`globals.global.videoStatus.${videoUrl.replace('', '')}`) }}
            <div v-if="videoUrl === 'processing'" class="progress" role="progressbar" aria-label="Video progress" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" :style="`width: ${progress}%`">{{ progress }}%</div>
            </div>
        </div>
        <div
            v-else-if="(isLocked && !loading)"
            :id="`directive-fullscreen-wrapper-video-${id}${isView?'-viewer':''}`"
            class="post-image-wrapper cursor-pointer position-relative"
            @click="toggleFullscreen"
        >
            <v-lazy-image
                :src="`${media.original}`"
                :src-placeholder="`${media.thumb}`"
                class="position-relative img-fluid img-size"
                :class="(checkedVisibility === 'blur' || (teaserPoster === '' && media.media_type === 'locked_video/ikonstar')) ? 'blur-img' : ''"

            />
            <span v-if="(checkedVisibility === 'blur' || (teaserPoster === '' && media.media_type === 'locked_video/ikonstar'))" class="position-absolute start-50 top-50 translate-middle">
                <ContentUnlockerButton></ContentUnlockerButton>
            </span>
        </div>
        <div
            v-else-if="(isLocked && poster !== '' && poster !== null)"
            :id="`directive-fullscreen-wrapper-video-${id}${isView?'-viewer':''}`"
            class="post-image-wrapper cursor-pointer position-relative"
            @click="toggleFullscreen"
        >
            <v-lazy-image
                :src="`${poster}`"
                :src-placeholder="`${poster}`"
                class="position-relative img-fluid img-size"
                :class="(checkedVisibility === 'blur' || media.media_type === 'locked_video/ikonstar') ? 'blur-img' : ''"
            />
            <span class="btn btn-dark cursor-pointer position-absolute start-50 top-50 translate-middle">
                <i class="fa-solid fa-lock"></i>
                {{ $t('creator.post.unlock') }}
            </span>
        </div>
        <div v-else class="post-video-wrapper" :class="[isLoading, videoOrientation]">
            <template
                v-if="
                    videoUrl !== 'processing' &&
                    videoUrl !== 'pending' &&
                    videoUrl !== 'failed'
                ">
                <media-controller
                    :key="`media-video-${id}${isView?'-viewer':''}`"
                    v-pause-on-hide="{active: true, handler: handlePause}"
                    autohide="1"
                    nohotkeys
                >
                    <hls-video
                        v-if="(isM3u8 && canHLS)"
                        ref="videoElement"
                        crossorigin
                        playsinline
                        preload
                        :poster="videoPoster"
                        :src="videoUrl"
                        :muted="preferMuted"
                        slot="media"
                    ></hls-video>
                    <video
                        v-else
                        ref="videoElement"
                        crossorigin
                        playsinline
                        preload
                        :src="videoUrl"
                        :poster="videoPoster"
                        :muted="preferMuted"
                        slot="media"
                    ></video>
                    <div class="ytp-gradient-bottom"></div>
                    <div slot="centered-chrome">
                        <media-loading-indicator class="position-absolute top-50 start-50 translate-middle text-white bg-black rounded-pill p-3">
                            <div slot="icon" class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </media-loading-indicator>
                        <media-play-button class="position-absolute top-50 start-50 translate-middle text-white bg-dark-50 rounded-pill p-3">
                            <i slot="play" class="fa-solid fa-play fs-2 m-0 p-0 text-white"></i>
                            <i slot="pause" class="fa-solid fa-pause fs-2 m-0 p-0 ms-n1"></i>
                        </media-play-button>
                    </div>
                    <media-rendition-menu hidden anchor="auto"></media-rendition-menu>
                    <media-time-range></media-time-range>
                    <media-control-bar>
                        <media-play-button></media-play-button>
                        <media-mute-button></media-mute-button>
                        <!--<media-volume-range></media-volume-range>-->
                        <media-time-display showduration></media-time-display>
                        <span class="control-spacer"></span>
                        <!--<media-playback-rate-button></media-playback-rate-button>-->
                        <!--<media-pip-button></media-pip-button>-->
                        <media-rendition-menu-button v-if="isHLS && canHLS"></media-rendition-menu-button>
                        <media-fullscreen-button></media-fullscreen-button>
                    </media-control-bar>
                </media-controller>

            </template>
        </div>
    </div>
</template>
<script setup>
import {computed, onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import {useUserStore} from "@/stores/user";
import {useRegistrationCtaModalStore} from "@/stores/registrationCtaModal";
import VLazyImage from "v-lazy-image";
import { api as fullscreen } from 'vue-fullscreen'
import LightVideoPlayer from "@/components/LightVideoPlayer.vue";
import {useGlobalSettingsStore} from "@/stores/globalSettings";
import {storeToRefs} from "pinia";
import ContentUnlockerButton from "@/components/Buttons/ContentUnlockerButton.vue";

const props = defineProps({
    id: {
        type: String,
        default: '0'
    },
    media: {
        type: Object,
        default: () => ({
            original: false,
            thumb: false
        })
    },
    video: {
        type: String,
        default: null
    },
    poster: {
        type: String,
        default: ''
    },
    teaserPoster: {
        type: String,
        default: ''
    },
    checkedVisibility: {
        type: String,
        default: 'blur'
    },
    pauseOnHide: {
        type: Boolean,
        default: false,
    },
    isView: {
        type: Boolean,
        default: false,
    },
    progress: {
        type: String,
        default: '0',
    },
})

const loading = ref(true)

const videoElement = ref(null)
const videoOrientation = ref("portrait")

const fullscreenImage = ref(false)
const imageOrientation = ref("landscape")

window.videoPlayers = window.videoPlayers || [];
window.audioPlayers = window.audioPlayers || [];

/**
 * Check if user has already changed MUTE element
 * @type {ComputedRef<boolean|boolean>}
 */
const preferMuted = computed(() => {
    return localStorage.getItem('media-chrome-pref-muted')
        ? localStorage.getItem('media-chrome-pref-muted') === 'true'
        : true
})
/**
 * Check if video is new encoded format
 * @type {ComputedRef<unknown>}
 */
const isHLS = computed(() => {
    return props.video !== '' && props.video !== null
})
const isM3u8 = computed(() => {
    return isHLS.value && props.video.endsWith('.m3u8')
})

const settingsStore = storeToRefs(useGlobalSettingsStore())
/**
 * Check if browser has HLS support
 * @type {ComputedRef<boolean>}
 */
const canHLS = computed(() => {
    // console.log('canHls', Hls.isSupported())
    return settingsStore.settings.value.hlsSupported
})

/**
 * Compute SRC for video
 * Add timestamp if needed for iOS with native HLS
 * @type {ComputedRef<*|string>}
 */
const videoUrl = computed(() => {
    return (canHLS.value && isHLS.value)
        ? props.video
        : (isHLS.value ? props.video : props.media.original)
})

/**
 * Check if video can be shown
 * @type {ComputedRef<string>}
 */
const isLoading = computed(() => {
    return loading.value ? 'd-none': 'd-flex'
})

/**
 * Check if media is locked
 * @type {ComputedRef<boolean>}
 */
const isLocked = computed(() => {
    return !isHLS.value
        ? props.media.media_type?.startsWith('locked')
        : false
})

/**
 * Get Video Poster
 * @type {ComputedRef<boolean>}
 */
const videoPoster = computed(() => {
    return props.poster !== ''
        ? props.poster
        : false
    // return props.teaserPoster !== ''
    //     ? props.teaserPoster
    //     : (
    //         props.poster !== ''
    //             ? props.poster
    //             : false
    //     )
})

function lockedVideo(){
    if (useUserStore().cannot('listen-post-audio')) {
        useRegistrationCtaModalStore().showModal()
        return false
    }
}

function handlePause(){
    videoElement.value.pause()
}

const fromClick = ref(false)
function toggleVideo(){
    fromClick.value = true
    if (videoElement.value.paused) {
        videoElement.value.play()

        window.videoPlayers.forEach(p => {
            if (p && p !== videoElement.value && !p.paused) p.pause();
        });
        window.audioPlayers.forEach(p => {
            if (p && p.isPlaying()) p.pause();
        });
    }
    else{
        videoElement.value.pause()
    }
}

function fullscreenCallback(isFullscreen) {
    fullscreenImage.value = isFullscreen
    if (isFullscreen) {
        document.body.classList.add('overflow-hidden')
    }
    else {
        document.body.classList.remove('overflow-hidden')
    }
}

async function toggleFullscreen(isFullscreen) {
    if (!isLocked.value){
        await fullscreen.toggle(
            document.querySelector(`#directive-fullscreen-wrapper-video-${props.id}${props.isView?'-viewer':''}`), {
                teleport: false,
                pageOnly: true,
                callback: fullscreenCallback
            }
        )
    }
    else if (
        useUserStore().cannot('zoom-post-image')
        && (
            props.checkedVisibility === 'blur'
            || (
                props.checkedVisibility === 'teaser'
                && props.media.media_type === 'locked_video/ikonstar'
            )
        )) {
        useRegistrationCtaModalStore().showModal()
        return false
    }
}
let img = null;
function setupImage(){
    img = new Image();
    img.addEventListener('load', imageListener);
    img.src = props.media.thumb !== '' ? props.media.thumb : props.media.original
}

function imageListener() {
    const {
        naturalHeight,
        naturalWidth
    } = img;
    if (naturalHeight > naturalWidth) {
        imageOrientation.value = "portrait";
    }
    else {
        imageOrientation.value = "landscape";
    }
    loading.value = false
}


function cleanupImage(){
    img.removeEventListener('load', imageListener)
}

function setupVideo(){

    localStorage.setItem('media-chrome-pref-volume', 1)
    // if (props.isView){
        loading.value = false
        videoOrientation.value = "landscape"
    // }

    videoElement.value.addEventListener('loadedmetadata', videoEventLoadedmetadata);

    videoElement.value.addEventListener('canplay', videoEventCanplay);

    if (!props.isView) {
        videoElement.value.addEventListener('play', videoEventPlay);
        videoElement.value.addEventListener('volumechange', videoEventVolumechange);
    }
}

function videoEventLoadedmetadata(e) {
    if (e.target.clientHeight > e.target.clientWidth) {
        videoOrientation.value = "portrait"
    } else {
        videoOrientation.value = "landscape"
    }
    loading.value = false
}
function videoEventCanplay(e) {
    if (!props.isView) {
        window.videoPlayers.push(videoElement.value);
    }
    loading.value = false
    if (fromClick.value){
        videoElement.value.play()
    }
}
function videoEventPlay(e) {
    window.videoPlayers.forEach(p => {
        if (p && p !== videoElement.value && !p.paused) p.pause();
    });
    window.audioPlayers.forEach(p => {
        if (p && p.isPlaying()) p.pause();
    });
}
function videoEventVolumechange(e) {
    localStorage.setItem('media-chrome-pref-muted', videoElement.value.muted)

    if (videoElement.value.muted) {
        window.videoPlayers.forEach(p => {
            if (p && p !== videoElement.value && !p.muted) p.muted = true;
        });
    }
    else {

        window.videoPlayers.forEach(p => {
            if (p && p !== videoElement.value && p.muted) p.muted = false;
        });
    }
}


function cleanupVideo(){

    videoElement.value.removeEventListener('loadedmetadata', videoEventLoadedmetadata);

    videoElement.value.removeEventListener('canplay', videoEventCanplay);

    if (!props.isView) {
        videoElement.value.removeEventListener('play', videoEventPlay);
        videoElement.value.removeEventListener('volumechange', videoEventVolumechange);
    }
}
// const testVideo = ref(null)
onMounted(() => {
    if (isLocked.value){
        setupImage()
    }
    else if (videoUrl.value !== 'processing' &&
        videoUrl.value !== 'pending' &&
        videoUrl.value !== 'failed'){
        // console.log(testVideo.value.canPlayType('application/vnd.apple.mpegurl'))
        setupVideo()
        // loading.value = false
    }
    else {
        loading.value = false
    }
})

onBeforeUnmount(() => {
    if (isLocked.value){
        cleanupImage()
    }
    else if (videoUrl.value !== 'processing' &&
        videoUrl.value !== 'pending' &&
        videoUrl.value !== 'failed'){
        cleanupVideo()
    }
})
</script>


<style scoped lang="scss">
.blur-img {
    filter: blur(14px) !important;
}

.v-lazy-image {
    filter: blur(5px);
    transition: filter 1.6s;
    will-change: filter;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

.post-video-wrapper {
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: hidden;
    //border: 1px solid rgb(47, 51, 54);
    max-height: 510px;


    &.portrait:not(.fullscreen) {
        max-width: 402px;
    }

    &.fullscreen {
    }
    #masterclass-header &{
        max-height: 350px;
        width: 100%;
        border-radius: 0;
    }
    #live-event-form &{
        max-height: 300px;
        width: 100%;
        border-radius: 0;
    }
}


:host {
    --media-secondary-color: transparent;
    --media-primary-color: #fff;
    --media-text-color: white
}

media-controller {
    font-size: 13px;
    font-family: Roboto, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    --media-control-background: transparent;
    --media-menu-background: rgba(1, 1, 1, 0.8);
    --media-control-hover-background: transparent;
    --media-range-track-height: 3px;
    --media-range-thumb-height: 13px;
    --media-range-thumb-width: 13px;
    --media-range-thumb-border-radius: 8px;
    --media-preview-thumbnail-border: 2px solid #fff;
    --media-preview-thumbnail-border-radius: 2px;
    --media-preview-time-margin: 5px 0 0;

}
media-loading-indicator[medialoading][mediapaused] {
    display: none;
}
media-loading-indicator:not([medialoading]) {
    display: none;
}

media-loading-indicator[medialoading]:not([mediapaused]) + media-play-button {
    display: none;
}

media-control-bar {
    position: relative;
}

media-control-bar:last-child {
    padding: 0 10px 0 5px;
}

media-play-button {
    --media-button-icon-width: 30px;
    padding: 6px 10px;
}

media-time-range {
    width: 100%;
    height: 5px;
    z-index: 0;

    --media-range-track-transition: height 0.1s linear;
    --media-range-track-background: rgba(255,255,255,.2);
    --media-range-track-pointer-background: rgba(255,255,255,.5);
    --media-time-range-buffered-color: rgba(255,255,255,.4);

    --media-range-bar-color: rgb(255, 174, 0);

    --media-range-thumb-border-radius: 13px;
    --media-range-thumb-background: rgb(255, 213, 0);

    --media-range-thumb-transition: transform 0.1s linear;
    --media-range-thumb-transform: scale(0) translate(0%, 0%);
}

media-time-range:hover {
    --media-range-track-height: 5px;
    --media-range-thumb-transform: scale(1) translate(0%, 0%);
}

media-volume-range {
    --media-range-track-background: rgba(255,255,255,.2);
}

.control-spacer {
    flex-grow: 1;
}

media-mute-button + media-volume-range {
    width: 0;
    overflow: hidden;
    transition: width 0.2s ease-in;
}

/* Expand volume control in all relevant states */
media-mute-button:hover + media-volume-range,
media-mute-button:focus + media-volume-range,
media-mute-button:focus-within + media-volume-range,
media-volume-range:hover,
media-volume-range:focus,
media-volume-range:focus-within {
    width: 70px;
}
div[slot="centered-chrome"] {
    media-play-button {
        position: relative;
        flex: none;
        display: flex;
        margin-bottom: 10px;
        padding-left: 16px;
        align-items: center;
        justify-items: center;
        height: 60px;
        width: 60px;
        background: rgb(0 0 0 / 0.8);
        border-radius: 9999px;
    }
}

div[slot="centered-chrome"] media-play-button:hover {
    box-shadow: 0 0 0 calc(2px) var(--media-tertiary-color);
}

.ytp-gradient-bottom {
    padding-top: 37px;
    position: absolute;
    width: 100%;
    height: 170px;
    bottom: 0;
    pointer-events: none;
    background-position: bottom;
    background-repeat: repeat-x;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACqCAYAAABsziWkAAAAAXNSR0IArs4c6QAAAQVJREFUOE9lyNdHBQAAhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5jsP56Xz8PM5gcC/xfDEmjhKxEOCSaREEiSbFEqkQppJpzJMJiWyINvkUCIX8kw+JQqg0BRRxaaEEqVQZsopUQGVpooS1VBjglStqaNEPTSYRko0QbNpoUQrtJl2qsN0UqILuk0PJXqhz/RTYgAGzRA1bEYoMQpjZpwSExAyk5SYgmkzQ82aOUqEIWKilJiHBbNIiSVYhhVYhTVYhw3YhC3Yhh3YhT3YhwM4hCM4hhM4hTM4hwu4hCu4hhu4hTu4hwd4hCd4hhd4hTd4hw/4hC/4hh/4/QM2/id28uIEJAAAAABJRU5ErkJggg==");
}

.post-image-wrapper {
    background-size:cover;
    background-position: center center;
    //transition: all .2s linear;

    border-radius: 8px;
    #masterclass-header &{
        border-radius: 0;
    }
    overflow-x: hidden;
    overflow-y: hidden;
    //border: 1px solid rgb(47, 51, 54);
    max-height: 510px;

    &.portrait:not(.fullscreen) {
        max-width: 402px;
    }

    &.fullscreen {
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 1040;
        background-color: rgba(0, 0, 0, 0.99);
        max-height: 100%;

        height: 100vh !important;
        width: 100vw !important;

        img {
            max-height: 100%;
        }
    }
}
</style>
