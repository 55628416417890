<template>
    <div class="" :class="classes">
        <div v-for="(item, key) in props.items" :key="`${item.id}-${component}`" :class="childClasses">
            <component
                :is="props.component"
                :item="item"
                :router-name="routerName"
                :extra-data="props.extraData"
                v-bind="{
                    ...$attrs,
                    ...extraComponentProps,
                    pauseOnHide
                }"
                @replace="$emit('replace')"
                @clicked="clicked(item)"
                @updated="updated(item.id, key, $event)"
                @deleted="deleted(key, type, item.id)"
                @view:episode="viewEpisode(item)"
            ></component>
        </div>
        <div v-if="!loading && items.length > 0 && props.hasMore" v-intersect="loadMore" class="mt-2 p-5"></div>
        <div v-if="loading && props.hasMore" class="col-12 text-center mt-2">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ t('globals.loading') }}</span>
            </div>
        </div>
    </div>
    <!--    <div v-if="items.length > 0 && !props.hasMore" class="row mt-5">-->
    <!--        <div class="col-12 text-center">-->
    <!--            {{ t('globals.noMore') }}-->
    <!--        </div>-->
    <!--    </div>-->
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import {onMounted, onUnmounted, ref, watch} from 'vue'
// import { gsap } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

const { t } = useI18n()
const props = defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
    },
    hasMore: {
        type: Boolean,
        default: true,
    },
    pauseOnHide: {
        type: Boolean,
        default: false,
    },
    component: {
        type: String,
        required: true,
    },
    classes: {
        type: String,
        default: null,
    },
    childClasses: {
        type: String,
        default: null,
    },
    routerName: {
        type: String,
        default: null,
    },
    extraComponentProps: {
        type: Object,
        default: null,
    },
    extraData: {
        type: Object,
        default: null,
    },
    type: {
        type: String,
        default: null,
    },
})

const emit = defineEmits(['loadMore', 'clicked', 'updated', 'deleted', 'replace', 'view:episode'])
// const videoDivs = ref()

const unwatch = watch(
    props.items,
    () => {
        setTimeout(() => {
            loading.value = false
        }, 700)
    },
    { deep: true }
)

const loading = ref(true)

function loadMore(isIntersecting) {
    if (isIntersecting && props.hasMore) {
        loading.value = true
        emit('loadMore')
    }
}

onMounted(() => {
    if (props.items.length) {
        setTimeout(() => {
            loading.value = false
        }, 700)
    }

    if (props.component === 'post-content') {
        // gsap.registerPlugin(ScrollTrigger)
        // // Observe scroll
        // window.addEventListener("scroll", function() {
        //     // Refresh list of players video due infinite scroll
        //     videoDivs.value = gsap.utils.toArray('.player-post');
        //     // Place a pointer on each video to know if I need to play or pause
        //     videoDivs.value.forEach((videoDiv, i) => {
        //         let videoElem = videoDiv.querySelector('video')
        //         ScrollTrigger.create({
        //             trigger: videoElem,
        //             start: 'top 45%',
        //             end: 'top 20%',
        //             markers: true,
        //             // onEnter: () => null,
        //             // onEnterBack: () => null,
        //             onLeave: () => videoElem.pause(),
        //             onLeaveBack: () => videoElem.pause(),
        //         });
        //     });
        // })
    }
})

onUnmounted(() => {
    unwatch()
})

function clicked(id) {
    emit('clicked', id)
}

function updated(id, key, $event) {
    emit('updated', { id, key, creatorId: $event })
}

function deleted(key, type, itemId = null) {
    emit('deleted', key, type, itemId)
}

function viewEpisode(item) {
    emit('view:episode', item)
}
</script>
