<template>

    <div class="card col border-0 text-center mb-3 {{ props.customClass }}">

        <template v-if="route !== ''">
            <div
                class="text-decoration-none text-dark cursor-pointer"
                :data-bs-dismiss="($breakpoints.mdAndDown.value) ? 'offcanvas' : ''"
                @click.prevent="redirect({name: route})"
            >
                <div class="card-body">

                    <div class="card-title">
                        {{ props.title }}
                    </div>
                    <div class="card-number">
                        {{ props.number }}
                    </div>
                </div>
            </div>
        </template>
        <template v-else>

            <div class="card-body">
                <div class="card-title">
                    {{ props.title }}
                </div>
                <div class="card-number">
                    {{ props.number }}
                </div>
            </div>
        </template>
    </div>

</template>

<script setup>
import router from "@/router";

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    number: {
        type: String,
        required: true,
    },
    customClass: {
        type: String,
        default: '',
    },
    route: {
        type: String,
        required: false,
        default: '',
    }
})

function redirect(_path) {
    router.push(_path)
}

</script>
<style scoped>
.card-primary {
    /* background: #ffffff url('@/assets/images/card-stat-primary.svg') no-repeat;
    background-position: top right; */
}
.card-secondary {
    background: #8ed8e6 url('@/assets/images/card-stat-secondary.svg')
        no-repeat -3.625rem 0rem;
}

.card-title {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 90%;
    text-align: center;
    /* color: #000; */
}
.card-number {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 200%;
    text-align: center;
    /* color: #fff;
    text-shadow: 2px 2px 0px #c79f56; */
}
.card-secondary .card-number {
    /* text-shadow: 2px 2px 0px #57acbc; */
}
</style>
