const acceptedTypes = {
    photo: ['image/jpeg', 'image/png'],
    audio: ['audio/mpeg', 'audio/aac', 'audio/x-wav'],
    video: [
        'video/x-msvideo',
        'video/h264',
        'video/mp4',
        'video/mpeg',
        'video/ogg',
        'video/quicktime',
        'video/webm',
        'video/x-flv',
    ],
}

export default acceptedTypes
