import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import {ref, watch} from 'vue'
import { useUserStore } from '@/stores/user'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'
import {useCreatorStore} from "@/stores/frontend/creator";

const EMPTY_PODCAST = {
    id: null,
    title: '',
    media: null,
    video: null,
}

export const usePodcastsStore = defineStore('podcastsStore', () => {
    const errors = ref([])

    const hasActive = ref(false)
    const podcastModal = ref(EMPTY_PODCAST)
    let onDisplayCallbacks = []
    let onHideCallbacks = []

    const userStore = useUserStore()
    const creatorStore = useCreatorStore()

    const podcast = ref(null)

    const podcastInStorage = localStorage.getItem(`creator-${creatorStore.creator.id}-podcast`)
    if (podcastInStorage) {
        const storagePodcast = JSON.parse(podcastInStorage)
        podcast.value = {
            ...podcast.value,
            ...storagePodcast,
        }
    }
    watch(
        () => podcast,
        (state) => {
            localStorage.setItem(
                `creator-${creatorStore.creator.id}-podcast`,
                JSON.stringify(state.value)
            )
        },
        {deep: true}
    )


    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all series or specific one by type
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(query = {}, cursor = null) {

        // TODO TRY FIX SWITCH CREATOR
        // if (podcast.value !== null){
        //     return Promise.resolve([podcast.value])
        // }

        let queryFilter = ''

        if (cursor != null) {
            query.cursor = cursor
        }

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll('creator/' + userStore.selectedCreator.id + '/series' + queryFilter)
            .then(({ data }) => {
                return data.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all series episodes by type video
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function listEpisodes(query = {}, cursor = null) {
        // Query param builder
        let queryFilter = ''

        if (cursor != null) {
            query.cursor = cursor
        }

        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll('series/' + podcast.value.id + '/episode' + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function post(params) {
        return ApiService.post('series', params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item with form upload
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function create(params) {
        return ApiService.postFormData('series', params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(params) {
        params._method = 'PUT'
        return ApiService.postFormData('series/' + params.id, params)
            .then(({ data }) => {
                podcast.value = null
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(id) {
        return ApiService.get('series', id)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getByIdForCreator() {

        let apiUrl = `creator/${creatorStore.creator.id}/series`
        return ApiService.get(apiUrl, podcast.value.id)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function likedPodcasts(query = {}) {
        return ApiService.getAll('liked/podcasts', {
            params: query,
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Like/Unlike workout
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function like(serieId) {
        return ApiService.post('series/' + serieId + '/toggle-like')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a workout
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(workoutId) {
        return ApiService.delete('series/' + workoutId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item with form upload
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function createEpisode(params) {
        return ApiService.postFormData(
            'series/' + params.series_id + '/episode',
            params
        )
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateEpisode(params) {
        params._method = 'PUT'
        return ApiService.postFormData(
            'series/' + params.series_id + '/episode/' + params.id,
            params
        )
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getEpisodeById(episodeId) {
        return ApiService.get('series/' + podcast.value.id + '/episode/' + episodeId)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete an episode
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDeleteEpisode(episodeId) {
        return ApiService.delete('series/' + podcast.value.id + '/episode/' + episodeId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Reorder episodes
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function reorderEpisodes(ids) {
        return ApiService.post(
            'series/' + podcast.value.id + '/reorder-episodes',
            {ids}
        )
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Modal content
     */
    function onDisplay(callback) {
        onDisplayCallbacks.push(callback)
    }

    function onHide(callback) {
        onHideCallbacks.push(callback)
    }

    /**
     * Show comments of media
     */
    function showPodcast(displayed) {
        hasActive.value = true
        podcastModal.value = {
            id: displayed.id,
            title: displayed.title,
            media: displayed.media,
            video: displayed.video,
        }
        onDisplayCallbacks.forEach((callback) => callback())
    }

    /**
     * Hide comments of media
     */
    function hidePodcast() {
        hasActive.value = false
        podcastModal.value = EMPTY_PODCAST
        onHideCallbacks.forEach((callback) => callback())
    }


    return {
        errors,
        podcast,

        list,
        listEpisodes,
        create,
        post,
        update,
        getById,
        getByIdForCreator,
        softDelete,
        like,
        setErrors,
        likedPodcasts,

        createEpisode,
        updateEpisode,
        getEpisodeById,
        softDeleteEpisode,
        reorderEpisodes,

        hasActive,
        podcastModal,
        onDisplay,
        onHide,
        showPodcast,
        hidePodcast,
    }
})
